@import '@/App.less';

.product {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}
.banner {
    height:200px;
    position: relative;
    img {
        width: 100%;
        height: 100%
    }
}
.banner_title {
    position: absolute;
    left:50%;
    top:50%;
    white-space: pre;
    h4 {
        margin: 0;
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        color: #ffffff;
        text-shadow: 0px 2px 7px rgb(0 0 0 / 50%);
    }
}
.panel1 {
    margin: auto;
    background: #fff;
    padding: 24px 0;
    .content {
        .title {
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            color: #2c3e5c;
            margin-bottom: 0;
        }
        img {
            height: 20px;
        }
    } 
    .desc {
        margin: 16px 30px 7px;
        p{
            font-weight: 400;
            text-align: left;
            color: #667fa3;
            line-height: 24px;
            font-size: 12px;
        }
    }
    .descImg {
        height: 229px;
        display: block;
        padding:0 30px;
        img {
            width: 100%;
            height:100%;
        }
    }
}
.panel2{
    background: #f5f7fa;
    padding: 24px 0;
    .container {
        margin: 0 auto;
        .content {
            margin-bottom: 16px;
            .title {
                font-size: 18px;
                font-weight: bold;
                text-align: center;
                color: #2c3e5c;
                margin-bottom: 0;
            }
            img {
                height: 20px;
            }
        } 
        .productAdvantage {
            padding:0 30px;
            img {
                width: 89px;
                height: 89px;
            }
            h4 {
                font-size: 12px;
                font-weight: bold;
                color: #2c3e5c;
                text-align: left;
            }
            p{
                // width: 209px;
                text-align: left;
                display: block;
                margin: auto;
                font-size: 12px;
                color: #667FA3;
            }
            // >div:not(:last-child) {
            //     margin-top: 16px;
            // }
            .flex {
                margin-top: 16px;
                display: flex;
                // justify-content: space-around;
                // align-items: center;
                // height: 130px;
                .rightPanel {
                   margin-left: 17px;
                }
            }
        }
    }
}

.panel3{
    padding: 24px 0;
    .content {
        margin-bottom: 24px;
        .title {
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            color: #2c3e5c;
            margin-bottom: 4px;
        }
        img {
            height: 20px;
        }
    }
    .digitalScreen {
        padding: 0 30px;
        .flex {
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
        }
        .full_screen {
            margin: 24px auto 0;
            img {
                width: 70%;
                height: 100%;
            }
        }
        h4 {
            font-size: 12px;
            font-weight: bold;
            text-align: center;
            color: #2c3e5c;
            margin-bottom: 8px;
        }
        img {
            width: 100%;
            height:100%;
        }
        p{
            margin-top: 8px;
            margin-bottom: 0;
            font-size: 12px;
            font-weight: 400;
            text-align: center;
            color: #667fa3;
        }
    }
    .productCard{
        width: 40%;
        height: 100%;
    }
}

.panel4{
    padding: 24px 0;
    margin: auto;
    background: #f5f7fa;
    .content {
        margin-bottom: 16px;
        .title {
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            color: #2c3e5c;
            margin-bottom: 4px;
        }
        img {
            height: 20px;
        }
    }
    .desc {
        margin: auto 30px;
        p{
            font-weight: 400;
            text-align: left;
            color: #667fa3;
            line-height: 24px;
            font-size: 12px;
            margin-bottom: 0;
        }
    }
    .descImg {
        margin: auto;
        img {
            width: 100%;
            height:100%;
        }
    }
}

.newBox{
    margin-top: 9px;
    .titleBox{
        text-align: center;
        font-size: 17px;
        font-weight: 500;
        color: #2C3E5C;
        img{
            width: 120px;
            height: 19px;
        }
    }
    .contentBox{
        // width: 315px;
        margin: 16px 30px 0;
        font-size: 11px;
        font-weight: 400;
        color: #667FA3;
        text-align: start;
    }
    .newImgBox{
        margin-top: 24px;
        text-align: center;
        height: 173px;
        img{
            // width: 100%;
            height: 100%;
        }
    }
}