@import '@/App.less';

.contact {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}
.banner {
    height:200px;
    position: relative;
    img {
        width: 100%;
        height: 100%
    }
}
.banner_title {
    position: absolute;
    left:50%;
    top:50%;
    white-space: pre;
    h4 {
        margin: 0;
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        color: #ffffff;
        text-shadow: 0px 2px 7px rgb(0 0 0 / 50%);
    }
}

.panel1 {
    padding: 24px 0 0;
    .content {
        margin: auto;
        h4 {
            font-size: 18px;
            font-weight: 600;
            text-align: center;
            color: #2c3e5c;
            margin-bottom: 0;
        }
        .title_img {
            height: 20px;
            margin-bottom: 24px;
        }
        .cooperationCase {
            padding: 0 30px 0;
            >div:nth-child(odd) {
                margin-right: 10%;
            }
            .last {
                width: 45%;
                .templateTitle {
                    font-size: 12px;
                    font-weight: 600;
                    text-align: center;
                    color: #2c3e5c;
                    margin-top: 8px;
                }
            }
            .box{
                display: inline-block;
                width: 45%;
                margin-bottom: 24px;
                .templateTitle {
                    font-size: 12px;
                    font-weight: 600;
                    text-align: center;
                    color: #2c3e5c;
                    margin-top: 4px;
                }
            }
            .bgTemplate {
                position: relative;
                margin: auto;
                background: url("~@/assets/images/cooperate/bg.png") no-repeat;
                background-size: cover;
                img {
                    width: 100%;
                    height: 100%;
                    margin-top: 20%;
                    padding: 0 5% 12px;
                }
            }
            :global{
                .adm-list-item {
                    display: inline-block;
                }
                .adm-list-body {
                    border: none;
                }
                .adm-list-item-content {
                    border-top: none;
                }
            }
        }
    }
}