@import '@/App.less';

.product {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}
.banner {
    height:440px;
    position: relative;
    img {
        width: 100%;
        height: 100%
    }
}
.banner_title {
    position: absolute;
    left:50%;
    top:50%;
    width: 540px;
    height: 168px;
    h4 {
        margin: 0;
        font-size: 60px;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        line-height: 84px;
        text-shadow: 0px 2px 7px rgb(0 0 0 / 50%);
    }
}
.panel1 {
    height:1912px;
    width: 1200px;
    margin: auto;
    background: #fff;
    padding: 70px 0;
    .content {
        .title {
            font-size: 32px;
            font-weight: 600;
            text-align: center;
            color: #2c3e5c;
            margin-bottom: 4px;
        }
        img {
            height: 37px;
        }
    } 
    .desc {
        margin: 40px auto 0;
        width: 1100px;
        p{
            font-weight: 400;
            text-align: left;
            color: #667fa3;
            line-height: 24px;
            font-size: 16px;
        }
    }
    .descImg {
        width: 1235px;
        height: 710px;
        display: block;
        margin: 0 auto 47px;
        img {
            width: 100%;
            height:100%;
        }
    }
    .desc1 {
        margin: 40px auto 0;
        width: 1100px;
        p{
            font-weight: 400;
            text-align: left;
            color: #667fa3;
            line-height: 24px;
            font-size: 16px;
            margin-bottom: 40px;
        }
    }
    .descImg1 {
        width: 1198px;
        height: 607px;
        display: block;
        margin: 0 auto 70px;
        img {
            width: 100%;
            height:100%;
        }
    }
}
.panel2{
    height: 1109px;
    background: #f5f7fa;
    padding: 70px 0 98px;
    .container {
        width:1200px;
        margin: 0 auto;
        .content {
            margin-bottom: 24px;
            .title {
                font-size: 32px;
                font-weight: 600;
                text-align: center;
                color: #2c3e5c;
                margin-bottom: 4px;
            }
            img {
                height: 37px;
            }
        } 
        .productAdvantage {
            img {
                width: 177px;
                height: 177px;
            }
            h4 {
                font-size: 24px;
                font-weight: 600;
                color: #2c3e5c;
                margin: 24px 0 13px;
            }
            p{
                width: 440px;
                text-align: left;
                display: block;
                margin: auto;
                font-size: 16px;
                color: #667FA3;
            }
            >div:nth-child(2) {
                margin-top: 60px;
            }
        }
    }
}

.panel3{
    height: 823px;
    padding: 70px 0;
    .content {
        margin-bottom: 24px;
        .title {
            font-size: 32px;
            font-weight: 600;
            text-align: center;
            color: #2c3e5c;
            margin-bottom: 4px;
        }
        img {
            height: 37px;
        }
    }
    .digitalScreen {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: end;
        justify-content: space-between;
        h4 {
            font-size: 24px;
            font-weight: 600;
            text-align: center;
            color: #2c3e5c;
            margin-bottom: 24px;
        }
        img {
            width: 100%;
            height:100%;
        }
        p{
            margin-top: 23px;
            margin-bottom: 0;
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            color: #667fa3;
        }
    }
}

.panel4{
    width: 1200px;
    height: 745px;
    padding: 70px 0;
    margin: auto;
    .content {
        margin-bottom: 24px;
        .title {
            font-size: 32px;
            font-weight: 600;
            text-align: center;
            color: #2c3e5c;
            margin-bottom: 4px;
        }
        img {
            height: 37px;
        }
    }
    .desc {
        margin: auto;
        width: 1111px;
        p{
            font-weight: 400;
            text-align: left;
            color: #667fa3;
            line-height: 24px;
            font-size: 16px;
        }
    }
    .descImg {
        width: 1275px;
        height: 437px;
        img {
            width: 100%;
            height:100%;
        }
    }
}