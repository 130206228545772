@import '@/App.less';

.about {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}
.banner {
    height:440px;
    position: relative;
    img {
        width: 100%;
        height: 100%
    }
}
.banner_title {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 540px;
    height: 168px;
    h4 {
        margin: 0;
        font-size: 60px;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        line-height: 84px;
        text-shadow: 0px 2px 7px rgb(0 0 0 / 50%);
    }
}
.panel1 {
    height: 946px;
    width: 1200px;
    margin: auto;
    background: #fff;
    .content {
        padding-top: 70px;
        .title {
            font-size: 32px;
            font-weight: 600;
            text-align: center;
            color: #2c3e5c;
            margin-bottom: 4px;
        }
        img {
            height: 37px;
        }
    } 
    .desc {
        margin: 40px auto 50px;
        width: 1100px;
        p{
            font-weight: 400;
            text-align: justify;
            color: #667fa3;
            line-height: 24px;
            font-size: 16px;
        }
    }
    .descImg {
        width: 800px;
        height: 560px;
        display: block;
        margin: 0 auto;
        img {
            width: 100%;
            height:100%;
        }
    }
}

.panel2 {
    height: 1322px;
    width: 1200px;
    margin: auto;
    background: #fff;
    .content {
        padding: 70px 0;
        .title {
            font-size: 32px;
            font-weight: 600;
            text-align: center;
            color: #2c3e5c;
            margin-bottom: 4px;
        }
        img {
            height: 37px;
        }
        .enterpriseCulture {
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
            height:196px;
            img {
                width: 80px;
                height: 80px;
            }
            h4{
                font-size: 20px;
                font-weight: 600;
                text-align: center;
                color: #2c3e5c;
                line-height: 29px;
                margin: 27px 0 13px;
            }
            p{
                font-size: 16px;
                font-weight: 400;
                text-align: center;
                color: #667fa3;
                width:201px;
                margin-bottom: 0;
            }
        }
    } 
    .content1{
        padding-top: 0;
        .newFlexBox{
            width: 1200px;
            margin: 40px auto 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            .boxContent{
                width: 570px;
                height: 311px;
                background: #f5f7fa;
                border-radius: 8px;
                box-sizing: border-box;
                padding: 42px 50px;
                .titleBox{
                    display: flex;
                    align-items: center;
                    img{
                        height: 46px;
                    }
                    span{
                        font-size: 20px;
                        font-weight: 500;
                        color: #2C3E5C;
                        margin-left: 24px;
                    }
                }
                .contentBox{
                    font-size: 16px;
                    color:#7385a5 ;
                    margin-top: 13px;
                    text-align: start;
                    .fontBox{
                        color: #6d7fa1;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

.panel3{
    height: 680px;
    background: #f5f7fa;
    padding: 70px 0;
    .container {
        width:1200px;
        margin: 0 auto;
        .content {
            margin-bottom: 24px;
            .title {
                font-size: 32px;
                font-weight: 600;
                text-align: center;
                color: #2c3e5c;
                margin-bottom: 4px;
            }
            img {
                height: 37px;
            }
        } 
        .authorization {
            display: flex;
            justify-content: space-between;
            img {
                width:278px;
                height: 398px;
            }
        }
    }
}