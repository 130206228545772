@import '@/App.less';

.contact {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}
.banner {
    height:200px;
    position: relative;
    img {
        width: 100%;
        height: 100%
    }
}
.banner_title {
    position: absolute;
    left:50%;
    top:50%;
    white-space: pre;
    h4 {
        margin: 0;
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        color: #ffffff;
        text-shadow: 0px 2px 7px rgb(0 0 0 / 50%);
    }
}
.panel1 {
    margin: auto;
    background: #fff;
    padding: 24px 0 0;
    .content {
        .title {
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            color: #2c3e5c;
            margin-bottom: 4px;
        }
        img {
            height: 20px;
        }
    } 
    .contactNumber {
        margin: 16px auto 24px;
        padding: 0 24px;
        div{
            display: flex;
            align-items: center;
            margin-bottom: 8px;
        }
        span{
            font-size: 12px;
            font-weight: 400;
            color: #2c3e5c;
            margin-left: 4px;
        }
        img {
            width: 16px;
            height: 16px;
        }
    }
    .map{
        height: 244px;
        margin: 16px;
        position: relative;
        img {
          width: 100%;
          height: auto;
        }
        .cityMap {
            >div {
                height: 244px !important;
            }
            height: 100%;
            :global{
                .anchorBL{ display:none }
                .BMap_cpyCtrl { display:none }
            }
            
        }
    }
    .cityList {
        background: #ffffff;
        border-radius: 4px;
        padding: 32px 20px 0;
        div{
            margin-bottom: 32px;
            cursor: pointer;
            width: 50%;
            padding: 0 10px;
            text-align: left;
            vertical-align: top;
        }
        :not(:last-child) {
          display: inline-block;
        }
        img {
            width: 13px;
            height:15px;
        }
        h4 {
            font-size: 12px;
            font-weight: bold;
            text-align: left;
            color: #667fa3;
            margin-bottom: 8px;
            display: inline-block;
            margin-left: 8px;
        }
        p{
            font-size: 12px;
            font-weight: 400;
            text-align: left;
            color: #667fa3;
        }
    }
}