@import '@/App.less';

.contact {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}
.banner {
    height:440px;
    position: relative;
    img {
        width: 100%;
        height: 100%
    }
}
.banner_title {
    position: absolute;
    left:50%;
    top:50%;
    width: 780px;
    height: 84px;
    h4 {
        margin: 0;
        font-size: 60px;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        line-height: 84px;
        text-shadow: 0px 2px 7px rgb(0 0 0 / 50%);
    }
}

.panel1 {
    padding: 70px 0;
    .content {
        width: 1200px;
        margin: auto;
        h4 {
            font-size: 32px;
            font-weight: 600;
            text-align: center;
            color: #2c3e5c;
            margin-bottom: 0;
        }
        .title_img {
            height: 37px;
            margin-bottom: 60px;
        }
        .cooperationCase {
            .box{
                display: inline-block;
                margin-right: 32px;
                margin-bottom: 60px;
                .bgTemplate {
                    position: relative;
                    width: 266px;
                    height: 467px;
                    background: url("~@/assets/images/cooperate/bg.png") no-repeat;
                    background-size: cover;
                    img {
                        width: 238px;
                        height: 385px;
                        position: absolute;
                        top: 53px;
                        left:  13px;
                    }
                }
                .templateTitle {
                    font-size: 20px;
                    font-weight: 600;
                    text-align: center;
                    color: #2c3e5c;
                    margin-top: 16px;
                }
            }
            :global{
                .ant-list-items {
                    text-align: left;
                }
                .ant-list-item-no-flex {
                    display: inline-block;
                }
                .ant-list-lg .ant-list-item {
                    padding: 0;
                }
                .ant-list-split .ant-list-item {
                    border: none;
                }
                .ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
                    border: none;
                }
            }
        }
    }
}