@import '@/App.less';

.contact {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}
.banner {
    height:440px;
    position: relative;
    img {
        width: 100%;
        height: 100%
    }
}
.banner_title {
    position: absolute;
    left:50%;
    top:50%;
    width: 780px;
    height: 84px;
    h4 {
        margin: 0;
        font-size: 60px;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        line-height: 84px;
        text-shadow: 0px 2px 7px rgb(0 0 0 / 50%);
    }
}
.panel1 {
    width: 1200px;
    margin: auto;
    background: #fff;
    padding-top: 70px;
    padding-bottom: 100px;
    .content {
        .title {
            font-size: 32px;
            font-weight: 600;
            text-align: center;
            color: #2c3e5c;
            margin-bottom: 4px;
        }
        img {
            height: 37px;
        }
    } 
    .contactNumber {
        margin: 60px auto 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        div{
            display: flex;
            align-items: center;
        }
        span{
            font-size: 24px;
            font-weight: 400;
            color: #2c3e5c;
            margin-left: 4px;
        }
        img {
            width: 28px;
            height: 28px;
        }
    }
    .map{
        position: relative;
        img{
          width: 100%;
          height: auto;
        }
        .cityList {
            position: absolute;
            top: 19px;
            left: 32px;
            z-index: 9;
            width: 304px;
            height: 714px;
            background: #ffffff;
            border-radius: 4px;
            box-shadow: 0px 0px 16px 0px rgba(51,51,51,0.29); 
            padding: 32px 20px 0;
            div{
                margin-bottom: 32px;
                cursor: pointer;
            }
            h4 {
                font-size: 24px;
                font-weight: 500;
                text-align: left;
                color: #667fa3;
                line-height: 33px;
                margin-bottom: 8px;
            }
            p{
                font-size: 16px;
                font-weight: 400;
                text-align: left;
                color: #667fa3;
                line-height: 22px;
            }
        }
        .cityMap {
            :global{
                .anchorBL{ display:none }
                .BMap_cpyCtrl { display:none }
            }
            
        }
    }
}