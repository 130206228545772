@import '@/App.less';

.news {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}
.banner {
    height:440px;
    position: relative;
    img {
        width: 100%;
        height: 100%
    }
}
.banner_title {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 540px;
    height: 168px;
    h4 {
        margin: 0;
        font-size: 60px;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        line-height: 84px;
        text-shadow: 0px 2px 7px rgb(0 0 0 / 50%);
    }
}
.content {
    width: 1200px;
    margin: 70px auto;
    :global {
        .ant-list-lg .ant-list-item {
            padding: 32px 0;
        }
        .ant-list-pagination {
            margin-top: 32px;
        }
    }
}
.newsList {
    display: flex;
    justify-content: center;
}
.newsImg{
    width:370px;
    height: 190px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition-duration:0.5s;
        &:hover{
            transform:scale(1.1);
        }
    }
}
.newsContent{
    text-align: left;
    margin-left: 50px;
    width: 780px;
    position: relative;
    &:hover .title{
        color:#147BFF
    }
    .title {
        font-size: 18px;
        font-weight: 600;
        text-align: left;
        color: #2c3e5c;
        margin-bottom: 20px;
    }
    .summary {
        font-size: 16px;
        font-weight: 400;
        text-align: justify;
        color: #667fa3;
        margin-bottom: 26px;
    }
    .createdTime {
        margin-bottom: 0;
        opacity: 0.6;
        font-size: 14px;
        font-weight: 400;
        color: #667fa3;
        position: absolute;
        bottom: 0;
    }
}

// 新闻详情
.newsDetail {
    width: 100%;
    margin: auto;
    overflow: hidden;
    background: #EEEEEE;
    .breadcrumb {
        width: 1200px;
        margin: auto;
        text-align: left;
        margin: 23px auto 21px;
    }
    .content {
        width: 1200px;
        margin: auto;
        background: #FFFFFF;
        padding: 0 50px;
        margin-bottom: 70px;
        overflow: hidden;
        :global {
            .ant-list-lg .ant-list-item {
                padding: 32px 0;
            }
            .ant-list-pagination {
                margin-top: 32px;
            }
        }
    }
    .title {
        color: #667FA3;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        color: #667fa3;
        margin-bottom: 8px;
        padding-top: 54px;;
    }
    .time {
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        color: #999999;
    }
    .desc {
        font-size: 16px;
        font-weight: 400;
        text-align: justify;
        color: #666666;
        margin-bottom: 40px;
        img{
            max-width: 100% !important;
        }
        video{
            width: 100%;
        }
    }
    .descImg {
        width: 1000px;
        height: 715px;
        object-fit: cover;
    }
    .detailTitle {
        margin: 40px 0 32px;
        width: 1100px;
        p{
            text-align: left;
            font-size: 16px;
            font-weight: 400;
            text-align: justify;
            color: #666666;
            margin-bottom: 24px;
        }
    }
}
.footer {
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 32px 0 40px;
    p{
        text-align: left;
    }
    a:hover{
        color: #6EB4FF;
    }
}