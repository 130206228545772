@import '@/App.less';

.medium {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}
.banner {
    height:200px;
    position: relative;
    img {
        width: 100%;
        height: 100%
    }
    .banner_title {
        position: absolute;
        left:50%;
        top:50%;
        white-space: pre;
        h4 {
            margin: 0;
            font-size: 22px;
            font-weight: bold;
            text-align: center;
            color: #ffffff;
            text-shadow: 0px 2px 7px rgb(0 0 0 / 50%);
        }
    }
}


.panel1 {
    margin: auto;
    padding: 24px 0;
    p {
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        color: #667fa3;
        margin-bottom: 0;
    }
}

.panel2 {
    background: #f5f7fa;
    .content {
        margin: auto;
        padding: 24px 0 16px;
        .title {
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            color: #2c3e5c;
            margin-bottom: 0;
        }
        .title_img {
            height: 20px;
            margin-bottom: 8px;
        }
        p {
            margin-bottom: 13px;
            font-size: 12px;
            font-weight: 400;
            text-align: center;
            color: #667fa3;
        }
        .logo_img {
            width: 320px;
            height: 37px;
            margin: 4px auto 18px;
        }
        .advantageList {
            .card {
                border-radius: 2px;
                box-shadow: 0px 2px 14px 0px rgba(118,118,118,0.19); 
                background: #ffffff;
                margin: 0 30px 12px;
                padding: 20px;
                img {
                    width: 154px;
                    height: 118px;
                    margin-top:  8px;
                }
                h4{
                    font-size: 14px;
                    font-weight: bold;
                    text-align: center;
                    color: #2c3e5c;
                }
                p{
                    margin: auto;
                    font-size: 12px;
                    font-weight: 400;
                    text-align: left;
                    color: #667fa3;
                }
            }
        }
    }
}

.panel3 {
    margin: auto;
    padding: 24px 0;
    .title {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        color: #2c3e5c;
        margin-bottom: 0;
    }
    .title_img {
        height: 20px;
        margin-bottom: 16px;
    }
    .desc_img {
        width: 970px;
        height: 642px;
        margin: auto;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .desc {
        color: #36A5FC;
        font-size: 14px;
        font-weight: bold;
    }
    .box {
        padding: 0 24px;
    }
    .mediaCharacteristic {
        width: 84px;
        img {
            width: 45px;
            height: 45px;
        }
        p {
            font-size: 12px;
            font-weight: 400;
            color: #667FA3;
        }

    }
    .flex {
        display: flex;
        justify-content: space-around;
    }
}

.panel4 {
    background: #f5f7fa;
    .content {
        margin: auto;
        padding: 24px 0 16px;
        .title{
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            color: #2c3e5c;
            margin-bottom: 0;
        }
        .title_img {
            height: 20px;
            margin-bottom: 10px;
        }
        .diverseContent {
            .card {
                height: 134px;
                border-radius: 2px;
                box-shadow: 0px 2px 14px 0px rgba(118,118,118,0.19); 
                background: #ffffff;
                display: flex;
                align-items: center;
                margin: 12px 30px;
                .icon {
                    width: 56px;
                    height: 56px;
                    margin-left: 30px;
                }
                h4 {
                    font-size: 12px;
                    font-weight: bold;
                    text-align: left;
                    color: #2c3e5c;
                    margin-top: 28px;
                    margin-bottom: 4px;
                }
                p {
                    margin: auto;
                    text-align: left;
                }
                .desc {
                    margin-bottom: 24px;
                    color: #667FA3;
                    font-size: 12px;
                }
            }
            .rightPanel {
                width: 60%;
                margin-left: 20px;
            }
        }
    }
}

.panel5{
    margin: 24px auto;
    .content {
        margin: auto;
        .title {
            font-size: 18px;
            text-align: center;
            color: #2c3e5c;
            margin-bottom: 4px;
            font-weight: bold;
        }
        
        .title_img {
            height: 20px;
            margin-bottom: 16px;
        }
        .videoSwiper {
            overflow: hidden;
            position: relative;
            padding: 0 16px;
            .swiper {
                width: 100%;
                padding-top: 50px;
                padding-bottom: 50px;
              }
        
            .swiper_slide {
                position: relative;
                background-position: center;
                background-size: cover;
                width: 287px;
                height: 162px;
                z-index: 50;
              }
              :global {
                .swiper-button-prev:after {
                    font-size: 24px;
                }
              }
              :global {
                .swiper-button-next:after {
                    font-size: 24px;
                }
              }
              .videoPlay {
                  position: absolute;
                  z-index: 50;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  width: 30px;
                  height: 30px;
                  img {
                      width: 100%;;
                      height:100%;
                      width: 30px;
                      height: 30px;
                  }
              }
              .videoContent{
                  z-index: 0;
                  background: rgba(0,0,0,0.27);
                  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
                  position: absolute;
                  width: 100%;
                  bottom: 0;
                  left:0;
                  padding: 10px 28px;
                  h4 {
                    font-size: 14px;
                    font-weight: bold;
                    text-align: left;
                    color: #ffffff;
                    line-height: 28px;
                    letter-spacing: 1px;
                    text-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50); 
                    margin-bottom: 0;
                  }
                  p{
                    font-size: 12px;
                    font-weight: 400;
                    text-align: left;
                    color: #ffffff;
                    line-height: 20px;
                    letter-spacing: 1px;
                    text-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50); 
                    margin-bottom: 0;
                  }
              }
        }
    }
}


.newTopBox{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:15px ;
    font-weight: 500;
    color: #2C3E5C;
    text-align: center;
    margin-top: 24px;
    img{
        width: 19px;
        height: 18px;
        margin: 0 6px;
    }
}
.newBgcBox{
    background-image: url('../../assets/images/medium/mobileNewBgc.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .flexBox{
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 17px;
        box-sizing: border-box;
        padding: 0 38px;
        .flexThing{
            // margin-right: 70px;
            text-align: center;
            font-size: 11px;
            color: #667FA3;
            img{
                width: 45px;
                height: 45px;
                margin-bottom: 7px;
            }
            .line{
                width: 112px;
            }
            
        }
        .flexThing:nth-child(2n){
            margin-right: 0px;
        }
    }
    
    
    
}
.titleNewBox{
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    color: #2C3E5C;
    margin-top: 32px;
    img{
        height: 19px;
    }
}
.contentBox{
    margin: 16px 30px;
    font-size: 11px;
    color: #667FA3;
    text-align: start;
}
.imgFlexBox{
    padding: 0 19px;
    box-sizing: border-box;
    font-size:12px ;
    font-weight: 500;
    color: #2C3E5C;
    img{
        width: 100%;
    }
}
.alipayFlexBox{
    margin-top: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    .thing{
        margin-right: 78px;
        font-size: 11px;
        color: #667FA3;
        img{
            width: 40px;
            height: 40px;
            margin-bottom: 7px;
        }
    }
    .thing:last-child{
        margin-right: 0px;
    }
}
.aliPayImgBox{
    margin-top: 16px;
    box-sizing: border-box;
    padding: 0 21px;
    img{
        width: 100%;
    }
}
.colorBox{
    box-sizing: border-box;
    padding: 0 30px;
    text-align: start;
    .colorTitleBox{
        width: 68px;
        height: 25px;
        background: #fa8c16;
        line-height: 25px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        margin-top: 16px;
    }
    .colorContentBox{
        margin-top: 10px;
        font-size: 11px;
        color: #667FA3;
        display: flex;
        align-items: center;
        img{
            width: 16px;
            height: 16px;
            margin-right: 5px;
        }
    }
}
.roundFlexBox{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-flow: wrap;
    margin-top: 16px;
    box-sizing: border-box;
    padding: 0 49px;
    .roundThing{
        // margin-right: 30px;
        width: 124px;
        height: 124px;
        border-radius: 50%;
        text-align: center;
        .title{
            font-size: 12px;
            font-weight: 700;
            margin-top: 26px;
        }
        .number{
            font-size: 14px;
            font-weight: 700;
            margin-top: 4px;
        }
        .content{
            font-size: 11px;
            margin-top: 8px;
        }
    }
    .roundThing:nth-child(2n){
        margin-right: 0px;
    }
}