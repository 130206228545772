@import '~antd/dist/antd.css';
@font-family: Source Han Sans CN;
.App {
  min-width:1440px;
  margin: 0 auto;
  text-align: center;
  min-height:100vh;
  background: #fff;
  font-family: @font-family;
}
.MobileApp {
  margin: 0 auto;
  text-align: center;
  min-height:100vh;
  background: #fff;
  font-family: @font-family;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.animateTopFade{
  position: absolute;
  transform: translate(-50%,-50%);
  animation: animate-top-fade 1s ease-in-out;
  animation-delay: 0.1s;
  z-index: 1;
}

@keyframes animate-top-fade
{
  from {
      opacity: 0;
      transform: translate(-50%,-10%);
  }
  to {
      opacity: 1;
      transform: translate(-50%,-50%);
  }
}