@import '@/App.less';

.join {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    text-align: left;
}
.banner {
    height:200x;
    position: relative;
    img {
        width: 100%;
        height: 100%
    }
}
.banner_title {
    position: absolute;
    left: 50%;
    top: 50%;
    white-space: pre;
    h4 {
        margin: 0;
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        color: #ffffff;
        text-shadow: 0px 2px 7px rgb(0 0 0 / 50%);
    }
}
.panel1{
    .content {
        margin: auto;
        padding: 24px 0;
    }
    .flex {
        display: flex;
        padding: 0 24px;
    }
    .flex_right {
        width: 220px;
        text-align: left;
        margin-left: 16px;
    }
    img {
        width: 42px;
        height: 42px;
        margin-bottom: 20px;
        margin-left: 20px;
    }
    h4{
        font-size: 12px;
        font-weight: bold;
        color: #2c3e5c;
        margin-bottom:  4px;
    }
    p {
        font-size: 12px;
        font-weight: 400;
        color: #667fa3;
    }
}

.panel2 {
    // width: 1200px;
    margin: auto;
    margin: 0 24px 24px;
    :global {
        .ant-collapse-content-box {
            padding: 16px 20px !important;
        }
    }
}
.jobType {
    display: block;
    color: #333333;
    font-weight: 500;
    text-align: justify;
    color: #333333;
    font-size: 14px;
    margin-bottom: 16px;
}
:global {
    .anticon-down {
        font-size: 23px;
        vertical-align: baseline;
        font-weight: bold;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        display: none;
    }
}

.panelBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
        width: 16px;
        height: 16px;
        margin-right: 16px;
    }
    .btn {
        height: 30px;
    }
    .btn > span {
        font-size: 12px;
    }
}
.panelBox_left{
    display: flex;
    align-items: center;
}
.panelTitle {
    color:#333;
    font-weight: 500;
    margin-bottom: 0;
    text-align: left;
    font-size: 12px;
}
.panelDesc {
    color:#666;
    font-weight: 400;
    margin-bottom: 0;
    text-align: left;
    font-size: 12px;
}
.dutyTitle {
    text-align: left;
    font-weight: bold;
    margin-bottom: 0;
    font-size: 12px;
}
.dutyContent {
    text-align: left;
    white-space: pre-line;
    font-size: 12px;
}
.more {
   color: #667FA3;
   font-size: 12px;
   margin-top: 16px;
}

:global {
    .ant-table-thead {
        .ant-table-cell::before{
            height: 0 !important; 
        }
    }
    .ant-collapse .ant-collapse-borderless {
        background: #ffffff;
    }
    .ant-collapse-item{
        margin-bottom: 16px;
        background: #F5F5F5;
    }
    .ant-collapse-content-box {
        padding: 16px 24px;
    }
}

.apply {
    background: #F5F5F5;
}

.apply1 {
    padding: 24px 24px 0;
    p {
        font-size: 18px;
        font-weight: 500;
        text-align: left;
        color: #333333;
        margin-bottom: 0;
    }
    .panelDesc {
        color:#999999;
        font-weight: 400;
        margin-bottom: 0;
        text-align: left;
        font-size: 12px;
    }
}

.baseinfo {
    text-align: left;
    padding: 16px;
    display: flex;
    align-items: center;
    background: #F5F5F5;
}
.icon {
    width: 4px;
    height: 15px;
    background: #007aff;
    display: inline-block;
    margin-right: 4px;
}
.title {
    font-size: 16px;
    text-align: left;
    color: #333333;
}

.form {
    :global {
        .adm-form-item-label{
            font-size: 16px;
            text-align: left;
        }
        .adm-input {
            --font-size: 16px;
            --text-align: right;
        }
        .adm-list-default {
            margin-bottom: 42px;
        }
        .adm-form-footer {
            background: #ffffff;
            padding: 12px;
        }
        .adm-list-item-description {
            text-align: right;
        }
        .ant-upload-list-item-name {
            width: auto;
            flex: initial !important;
        }
        .ant-upload-list-item-card-actions {
            float: right
        }
        .ant-upload-list-item-card-actions {
            position: absolute !important;
            float: right;
        }
        .adm-button.adm-button-large {
            font-size: 15px;
        }
        .adm-list-item-content-prefix {
            width: 140px;
        }
    }
}
.uploadBox {
    background: #ffffff;
    padding: 18px 16px;
    .upload {
        border: 1px dashed rgba(0,0,0,0.20);
        padding: 20px;
    }
    img {
        width: 34px;
        height:42px;
        margin-bottom: 8px;
    }
    .title {
        font-size: 16px;
        color:#333333;
        text-align: center;
    }
    .desc {
        font-size:  12px;
        color: #999999;
        text-align: left;
    }
}
.jobTypeContainer {
    padding: 16px;
}
.jobTypeTitle {
    margin-bottom: 17px;;
}
.jobType {
    margin-bottom: 32px;
}

.jobTypeBox {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    padding: 4px 8px;
    height: 34px;
}