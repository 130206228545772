@import '@/App.less';
@image1: 302px;
@image2: 309px;
@image3: 303px;
@font42: 42px;
@font32: 32px;
@font24: 24px;
@font18: 18px;
@font16: 16px;
@font14: 14px;
@font12: 12px;

.home-container{
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}
.banner {
    position: relative;
    display: block;
    width: 100%;
    height: 200px;
    margin: 0 auto;
    background-image: url("~@/assets/images/home/bannerMobile.png");
    background-size: cover;
    background-position: center;
    overflow: hidden;
    .text{
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        white-space: pre;
        transform: translate(-50%, -50%);
        font-weight: bold;
        font-size: 22px;
        color: #ffffff;
        text-shadow: 0px 2px 7px rgba(0,0,0,0.5);
        overflow: hidden;
    }
}
.panel{
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    padding: 0 30px;
    .panelTitle{
        width: 100%;
        font-size: @font18;
        font-weight: bold;
        color: #2C3E5C;
        text-align: center;
    }
    .panelText{
        width: 100%;
        font-size: @font12;
        color: #667FA3;
        margin-top: 20px;
    }
}
.maxPanel {
    margin: 0 auto;
}
.maxContent{
    max-width: 1200px;
    margin: 0 auto;
}
.image1{
    display: block;
    width: @image1;
    height: 37px;
    margin: 12px auto 0 auto;
}
.flex{
    display: flex;
}
.flexBetween{
    justify-content: space-between;
}
.content{
    position: relative;
    margin-top: 32px;
    .contentTitle{
        text-align: center;
        font-weight: bold;
        font-size: @font14;
        color: #2C3E5C;
    }
    .smallContentTitle{
        font-size: @font12;
        color: #2C3E5C;
    }
    .contentText{
        margin: 20px auto 0 auto;
        font-size: @font12;
        color: #667FA3;
        text-align: left;
    }
}
.panel1{
    .panel();
    padding-bottom: 24px;
    background-image: linear-gradient(180deg, #e2f3ff, #f5f7fa);
    .flex{
        justify-content: center;
    }
    // .contentText{
    //     width: 315px;
    // }
    .image2{
        width: @image2;
        height: 200px;
        display: block;
        margin: 20px auto 0;
    }
    .image3{
        width: @image3;
        height: 206px;
        display: block;
        margin: 20px auto 0;
    }
}
.panel2{
    .panel();
    background-color: #FFFFFF;
    padding: 24px 0;
    .flex{
        justify-content: center;
    }
    .icon{
        display: block;
        width: 31px;
        height: 31px;
        margin: 0 auto;
    }
    .divline{
        position: absolute;
        width: 1px;
        height: 69px;
        background-color: #EEEEEE;
        right: 0;
        top: 80px;
    }
    .content{
        padding: 16px 16px 16px 30px;
        width: 300px;
        margin-top: 0;
        .contentTitle{
            margin-top: 21px;
        }
        .contentText{
            width: 100%;
            margin-top: 8px;
        }
    }
    .content:nth-child(2n){
        padding-left: 16px;
        padding-right: 30px;
    }
}

.image4{
    width: 315px;
    height: 232px;
}
.panel3{
    .panel();
    background-color: #F5F7FA;
    .panelTitle{
        margin-top: 10px;
        padding:24px 0;
        text-align: center;
    }
    .content{
        margin: 20px auto 0;
    }
    .contentText{
        margin-top: 15px;
    }
    .iconList{
        width: 100%;
        justify-content: space-between;
        margin: 24px auto 32px;
        .flex{
            align-items: center;
            margin-bottom: 10px;
        }
        .icon{
            width: 50px;
            height: 50px;
        }
        .content{
            width: auto;
            margin-left: 19px;
            margin-top: 0;
        }
        .contentText{
            width: 201px;
            margin-top: 1px;
        }
        .smallContentTitle{
            text-align: left;
        }
    }
}
.panel4{
    .panel();
    padding: 24px 0 20px 0;
    background: linear-gradient(180deg, #FFFFFF, #F9F9FB);
    overflow: hidden;
    .maxPanel {
        background-image: url("~@/assets/images/home/map-bg.jpg");
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
    }
    .panelTitle{
        margin-top: 0;
    }
    .flex{
        margin-top: 24px;
        padding-right: 16px;
    }
    .numberContent{
        flex: 1;
        text-align: center;
        .number{
            font-weight: bold;
            font-size: @font18;
            color: #147BFF;
            font-family: HarmonyOS_Sans_Bold, HarmonyOS_Sans_Bold-Regular;
        }
        .numberTips{
            font-size: 14px;
            margin-top: 4px;
            color: #A7ACBA;
        }
    }
    .mapChart{
        width: 300px;
        height: 230px;
        margin: 16px auto 0 auto;
    }
}

.panel5{
    .panel();
    padding: 20px 0;
    background: #FAFAFC;
    overflow: hidden;
    .panelTitle{
        margin-top: 0;
    }
    .swpierContainer{
        overflow: hidden;
        margin-top: 36px;
        .swiperSlideContainer{
            padding-bottom: 40px;
        }
        .swiperSlide{
            width: 100% !important;
            margin: 0 !important;
        }
        .flex{
            flex-wrap: wrap;
            justify-content: center;
        }
        .logoCustomer{
            width: 160px;
            height: auto;
        }
        :global {
            .swiper-pagination-bullet {
                width: 6px;
                height: 6px;
            }
        }
    }
}
/*
@media screen and (min-width: 1000px) and (max-width: 1200px) {
    @scale1: 0.8;
    @contentWidth: 492px*@scale1;
    .maxContent{
        max-width: 1000px;
    }
    .image2{
        width: @image2*@scale1 !important;
        height: auto !important;
    }
    .image3{
        width: @image3*@scale1 !important;
        height: auto !important;
    }
    .panel1 {
        .contentText {
            width: @contentWidth !important;
        }
    }
    .panel2{
        @p44: 44px*@scale1;
        @w212: 212px*@scale1;
        .content {
            width: @w212;
            padding: 0 @p44;
        }
    }
}
 */

 .newBox{
    // display: flex;
    // justify-content: center;
    // align-items: center;
    width: 100%;
    // flex-flow: wrap;
    .thing1{
        // width: 153px;
        height: 180px;
        background: linear-gradient(352deg,#f8fbff, #ffffff 89%);
        border-radius: 2px;
        box-shadow: 0px 2px 4px 0px rgba(102,156,222,0.15);
        box-sizing: border-box;
        text-align: center;
        padding:12px 14px 16px ;
        // margin-right: 10px;
        img{
            width: 60px;
            height: 60px;
        }
        .title{
            font-size: 14px;
            font-weight: 500;
            color: #2C3E5C;
            margin-top: 13px;
            margin-bottom: 4px;
        }
        .textContent{
            font-size: 11px;
            color: #667FA3;
            text-align: start;
        }
    }
    .thing1:last-child{
        margin-right: 0;
    }
    .thing2{
        height: 198px;
    }
 }
 .carNewBox{
    box-sizing: border-box;
    padding: 0 30px;
    .titleBox{
        display: flex;
        align-items: center;
        font-size:14px ;
        font-weight: 500;
        color: #2C3E5C;
        margin-top: 18px;
        img{
            width: 20px;
            height: 20px;
            margin-right: 8px;
            margin-left: 10px;
        }
    }
    .labelContent{
        font-size: 11px;
        color: #667FA3;
        text-align: start;
        margin-top: 6px;
        padding-left: 10px;

    }
    .centerBox{
        box-sizing: border-box;
        padding: 0 30px;
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
        margin-top: 12px;
        .lineBox{
            .number{
                font-size: 14px;
                font-weight: 500;
                color: #2C3E5C;
            }
            .column1{
                width: 37px;
                height: 119px;
                background: #1890ff;
                border-radius: 4px;
                margin: 7px auto 0;
            }
            .column2{
                width: 36px;
                height: 93px;
                background: #91d5ff;
                border-radius: 4px;
                margin: 12px auto 0;
            }
            .column3{
                width: 37px;
                height: 110px;
                background: #1890ff;
                border-radius: 4px;
                margin: 12px auto 0;
            }
            .txt{
                font-size: 11px;
                color: #64698B;
                margin-top: 6px;
            }
        }
    }
    .centerBgcBox{
        // width: 100%;
        height: 158px;
        margin-top: 12px;
        background-image: url('../../assets/images/home/bgcNew2.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        box-sizing: border-box;
        .lineFlexBox{
            height: 50%;
            display: flex;
            .box{
                width: 50%;
                height: 100%;
                .numbers{
                    margin-top: 14px;
                    font-size: 20px;
                    color: #2C3E5C;
                    font-weight: 500;
                    span{
                        color: #287FFF;
                    }
                }
                .textBox{
                    font-size: 13px;
                    color: #64698B;
                }
            }
        }
    }
 }