@import '@/App.less';

.join {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}
.banner {
    height:440px;
    position: relative;
    img {
        width: 100%;
        height: 100%
    }
}
.banner_title {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 680px;
    height: 84px;
    h4 {
        margin: 0;
        font-size: 60px;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        line-height: 84px;
        text-shadow: 0px 2px 7px rgb(0 0 0 / 50%);
    }
}
.panel1{
    background: url('~@/assets/images/joinUs/leftBar.png') no-repeat left center ,url('~@/assets/images/joinUs/rightBar.png') no-repeat right center;
    background-size: 113px 320px;
    .content {
        width: 1200px;
        height: 360px;
        margin: auto;
        padding: 60px 0 80px;
        display: flex;
        justify-content: space-between;
    }
    img {
        width: 130px;
        height: 130px;
        margin-bottom: 20px;
    }
    h4{
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        color: #2c3e5c;
        margin-bottom:  13px;
    }
    p {
        font-size: 16px;
        font-weight: 400;
        text-align: justify;
        color: #667fa3;
    }
}

.panel2 {
    width: 1200px;
    margin: auto;
    margin-bottom: 70px;
}

:global {
    .ant-table-thead {
        .ant-table-cell::before{
            height: 0 !important; 
        }
    }
}
.jobTypeContainer {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
}
.jobType {
    margin-right: 32px;
}

.jobTypeBox {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
    padding: 4px 8px;
    width: 75px;
    height: 34px;
    text-align: left;
    cursor: pointer;
}