.footer {
    width: 100%;
    overflow: hidden;
    height: 100%;
    margin: 0 auto;
    background: url('~@/assets/images/footer.png');
  }
  
  .footer_content {
    width: 1200px;
    margin: 0 auto;
    height: 244px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255,255,255,0.1);
  }
  .copyRight{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  .copyRight_content {
    opacity: 0.3;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 0;
  }
  .desc {
    opacity: 0.65;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: #ffffff;
    margin-bottom: 3px;
  }
  .title {
    font-weight: 600;
    text-align: left;
    color: #ffffff;
    margin-bottom: 3px;
  }
  .rightPlate {
    display: flex;
  }
  .rightPlate_content {
    .coveredCity {
      margin-bottom: 15px;
    }
  }
  .rightPlate_img {
    margin-right: 24px;
    img {
      width: 115px;
      height:115px;
      margin-bottom: 8px;
    }
  }