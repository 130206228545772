.footer {
    width: 100%;
    overflow: hidden;
    height: 100%;
    margin: 0 auto;
    background: #333333;
  }
  
  .footer_content {
    padding: 16px 30px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: end;
    border-bottom: 1px solid rgba(255,255,255,0.1);
  }
  .copyRight{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  .copyRight_content {
    opacity: 0.3;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 0;
  }
  .desc {
    opacity: 0.65;
    font-size: 12px;
    text-align: left;
    color: #ffffff;
    margin-bottom: 3px;
  }
  .title {
    text-align: left;
    color: #ffffff;
    margin-bottom: 10px;
    font-size: 12px;
  }
  .rightPlate {
    display: flex;
  }
  .rightPlate_content {
    .coveredCity {
      margin-bottom: 25px;
    }
  }
  .rightPlate_img {
    img {
      width: 63px;
      height:63px;
      margin-bottom: 8px;
    }
  }