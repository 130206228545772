@import '@/App.less';
@image1: 908px;
@image2: 638px;
@image3: 590px;
@font42: 42px;
@font32: 32px;
@font24: 24px;
@font18: 18px;
@font16: 16px;

.home-container{
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}
.banner {
    position: relative;
    display: block;
    width: 100%;
    height:640px;
    margin: 0 auto;
    background-image: url("~@/assets/images/home/banner.jpg");
    background-size: cover;
    background-position: center;
    overflow: hidden;
    .text{
        position: absolute;
        display: block;
        // width: 550px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 600;
        font-size: 60px;
        color: #ffffff;
        text-shadow: 0px 2px 7px rgba(0,0,0,0.5);
        overflow: hidden;
    }
}
.panel{
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    .panelTitle{
        width: 100%;
        margin-top: 75px;
        font-size: @font32;
        font-weight: bold;
        color: #2C3E5C;
        text-align: center;
    }
    .panelText{
        width: 100%;
        font-size: @font24;
        color: #667FA3;
        margin-top: 20px;
    }
}
.maxPanel {
    margin: 0 auto;
}
.maxContent{
    max-width: 1200px;
    margin: 0 auto;
}
.image1{
    display: block;
    width: @image1;
    height: 119px;
    margin: 12px auto 0 auto;
}
.flex{
    display: flex;
}
.flexBetween{
    justify-content: space-between;
}
.content{
    position: relative;
    margin-top: 32px;
    .contentTitle{
        text-align: center;
        font-weight: bold;
        font-size: @font24;
        color: #2C3E5C;
    }
    .smallContentTitle{
        font-size: @font18;
        color: #2C3E5C;
    }
    .contentText{
        margin: 20px auto 0 auto;
        font-size: @font16;
        color: #667FA3;
        text-align: left;
    }
}
.panel1{
    .panel();
    padding-bottom: 50px;
    background-image: linear-gradient(180deg, #e2f3ff, #f5f7fa);
    .flex{
        justify-content: center;
    }
    .contentText{
        width: 492px;
    }
    .image2{
        width: @image2;
        height: 433px;
        display: block;
        margin-top: 22px;
    }
    .image3{
        width: @image3;
        height: 401px;
        display: block;
        margin-top: 41px;
    }
}
.panel2{
    .panel();
    height: 914px;
    background-color: #FFFFFF;
    .flex{
        margin-top: 18px;
        justify-content: center;
    }
    .icon{
        display: block;
        width: 40px;
        height: 40px;
        margin: 0 auto;
    }
    .divline{
        position: absolute;
        width: 1px;
        height: 130px;
        background-color: #EEEEEE;
        right: 0;
        top: 80px;
    }
    .content{
        padding: 0 44px;
        width: 300px;
        .contentTitle{
            margin-top: 43px;
        }
        .contentText{
            width: 100%;
            margin-top: 20px;
        }
    }
}

.image4{
    width: 550px;
    height: 404px;
}
.panel3{
    .panel();
    padding: 126px 0 80px 0;
    background-color: #F5F7FA;
    .panelTitle{
        margin-top: 0;
        text-align: left;
    }
    .content{
        width: 488px;
        margin-top: 65px;
        margin-left: 79px;
    }
    .contentText{
        margin-top: 15px;
    }
    .iconList{
        width: 100%;
        margin-top: 72px;
        justify-content: space-between;
        .flex{
            align-items: center;
        }
        .icon{
            width: 115px;
            height: 115px;
        }
        .content{
            width: auto;
            margin-left: 19px;
            margin-top: 0;
        }
        .contentText{
            width: 201px;
            margin-top: 1px;
        }
        .smallContentTitle{
            text-align: left;
        }
    }
}
.panel4{
    .panel();
    padding: 80px 0 20px 0;
    background: linear-gradient(180deg, #FFFFFF, #F9F9FB);
    overflow: hidden;
    .maxPanel {
        background-image: url("~@/assets/images/home/map-bg.jpg");
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
    }
    .panelTitle{
        margin-top: 0;
    }
    .flex{
        margin-top: 24px;
    }
    .numberContent{
        flex: 1;
        text-align: center;
        .number{
            font-weight: bold;
            font-size: @font42;
            color: #147BFF;
        }
        .numberTips{
            font-size: 14px;
            margin-top: 4px;
            color: #A7ACBA;
        }
    }
    .mapChart{
        width: 685px;
        height: 530px;
        margin: 16px auto 0 auto;
    }
}

.panel5{
    .panel();
    padding: 80px 0 50px 0;
    background: #FAFAFC;
    overflow: hidden;
    .panelTitle{
        margin-top: 0;
    }
    .swpierContainer{
        overflow: hidden;
        margin-top: 36px;
        .swiperSlideContainer{
            padding-bottom: 40px;
        }
        .swiperSlide{
            width: 100% !important;
            margin: 0 !important;
        }
        .flex{
            flex-wrap: wrap;
        }
        .logoCustomer{
            width: 300px;
            height: auto;
        }
    }
}
/*
@media screen and (min-width: 1000px) and (max-width: 1200px) {
    @scale1: 0.8;
    @contentWidth: 492px*@scale1;
    .maxContent{
        max-width: 1000px;
    }
    .image2{
        width: @image2*@scale1 !important;
        height: auto !important;
    }
    .image3{
        width: @image3*@scale1 !important;
        height: auto !important;
    }
    .panel1 {
        .contentText {
            width: @contentWidth !important;
        }
    }
    .panel2{
        @p44: 44px*@scale1;
        @w212: 212px*@scale1;
        .content {
            width: @w212;
            padding: 0 @p44;
        }
    }
}
 */

 .flexNew{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    .contentBox{
        width: 540px;
        height: 236px;
        background: linear-gradient(352deg,#ffffff, #f8fbff 89%);
        border-radius: 8px;
        box-shadow: 0px 4px 12px 0px rgba(102,156,222,0.15);
        box-sizing: border-box;
        padding-top: 34px;
        img{
            width: 70px;
            height: 70px;
        }
        .contentTitle{
            font-size: 24px;
            color: #2C3E5C;
            font-weight: 500;
            margin-bottom: 14px;
        }
        .content1,.content2{
            font-size: 16px;
            color: #667FA3;
            text-align: center;
        }
    }
 }
 .whyFlexBox{
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 60px 44px 10px;

    .leftBox{
        .topBox{
            display: flex;
            align-items: center;
            img{
                width: 58px;
                height: 58px;
                margin-right: 16px;
            }
            .title{
                text-align: start;
                font-size: 24px;
                color: #2C3E5C;
                font-weight: 500;
            }
            .tip{
                margin-top: 8px;
                font-size: 16px;
                color: #667FA3;
            }
        }
        .centerBox{
            height: 281px;
            box-sizing: border-box;
            padding: 0 8px;
            display: flex;
            justify-content: space-around;
            align-items: flex-end;
            margin-top: 32px;
            .lineBox{
                .number{
                    font-size: 24px;
                    font-weight: 500;
                    color: #2C3E5C;
                }
                .column1{
                    width: 61px;
                    height: 199px;
                    background: #1890ff;
                    border-radius: 4px;
                    margin: 12px auto 0;
                }
                .column2{
                    width: 60px;
                    height: 155px;
                    background: #91d5ff;
                    border-radius: 4px;
                    margin: 12px auto 0;
                }
                .column3{
                    width: 61px;
                    height: 184px;
                    background: #1890ff;
                    border-radius: 4px;
                    margin: 12px auto 0;
                }
                .txt{
                    font-size: 18px;
                    color: #64698B;
                    margin-top: 8px;
                }
            }
        }
        .centerBgcBox{
            width: 539px;
            height: 281px;
            margin-top: 32px;
            background-image: url('../../assets/images/home/bgcNew.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            .lineFlexBox{
                height: 50%;
                display: flex;
                .box{
                    width: 50%;
                    height: 100%;
                    .numbers{
                        margin-top: 25px;
                        font-size: 38px;
                        color: #2C3E5C;
                        font-weight: 500;
                        span{
                            color: #287FFF;
                        }
                    }
                    .textBox{
                        font-size: 24px;
                        color: #64698B;
                    }
                }
            }
        }
    }
 }