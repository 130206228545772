@import '@/App.less';

.about {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}
.banner {
    height: 200px;
    position: relative;
    img {
        width: 100%;
        height: 100%
    }
}
.banner_title {
    position: absolute;
    left: 50%;
    top: 50%;
    white-space: pre;
    h4 {
        margin: 0;
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        color: #ffffff;
        text-shadow: 0px 2px 7px rgb(0 0 0 / 50%);
    }
}
.panel1 {
    margin: auto 30px;
    background: #fff;
    .content {
        .title {
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            color: #2c3e5c;
            margin-bottom: 4px;
            margin-top: 24px;
        }
        img {
            height: 20px;
        }
    } 
    .desc {
        margin: 16px auto 20px;
        p{
            font-weight: 400;
            text-align: justify;
            color: #667fa3;
            line-height: 24px;
            font-size: 12px;
        }
    }
    .descImg {
        display: block;
        margin: 0 auto;
        img {
            width: 100%;
            height:100%;
        }
    }
}

.panel2 {
    margin: auto 30px;
    background: #fff;
    .content {
        padding: 32px 0 0px;
        .title {
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            color: #2c3e5c;
            margin-bottom: 4px;
        }
        img {
            height: 20px;
        }
        .enterpriseCulture {
            margin-top: 40px;
            height:196px;
            margin: 16px auto 0;
            .flex {
                display: flex;
                margin-bottom: 10px;
            }
            img {
                width: 42px;
                height: 42px;
                margin-right: 14px;
            }
            h4{
                font-size: 12px;
                font-weight: 600;
                text-align: left;
                color: #2c3e5c;
            }
            p{
                font-size: 12px;
                font-weight: 400;
                text-align: left;
                color: #667fa3;
                width:230px;
            }
        }
    } 
}

.panel3{
    background: #f5f7fa;
    padding: 30px;
    .container {
        margin: 0 auto;
        .content {
            margin-bottom: 24px;
            .title {
                font-size: 18px;
                font-weight: bold;
                text-align: center;
                color: #2c3e5c;
                margin-bottom: 4px;
            }
            img {
                height: 20px;
            }
        } 
        .authorization {
            display: flex;
            justify-content: space-between;
            img {
                width: 50%;
                height: 100%;
            }
        }
    }
}
.threeYearBox{
    box-sizing: border-box;
    padding-bottom: 32px;
    .titleBox{
        text-align: center;
        font-size: 17px;
        font-weight: 500;
        color: #2C3E5C;
        img{
            width: 145px;
            height: 19px;
        }
    }
    .cardBox{
        // width: 316px;
        margin: 16px 30px 0;
        background: #f5f7fa;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 16px;
        .topBox{
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 500;
            color: #2C3E5C;
            margin-bottom: 12px;
            img{
                width: 32px;
                height: 28px;
                margin-right: 12px;
            }
        }
        .lineBox{
            text-align: start;
            font-size: 11px;
            color: #667FA3;
            .start{
                margin-right: 1em;
            }
        }
    }
}