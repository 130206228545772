@import '@/App.less';

.news {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}
.banner {
    height:200px;
    position: relative;
    img {
        width: 100%;
        height: 100%
    }
}
.banner_title {
    position: absolute;
    left: 50%;
    top: 50%;
    white-space: pre;
    h4 {
        margin: 0;
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        color: #ffffff;
        text-shadow: 0px 2px 7px rgb(0 0 0 / 50%);
    }
}
.content {
    margin: 24px auto 0;
    :global {
        .adm-list-body {
            border: none;
        }
    }
}
.newsList {
    display: flex;
    // justify-content: center;
}
.newsImg{
    display: inline-block;
    width: 35%;
    height: 67px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition-duration:0.5s;
        &:hover{
            transform:scale(1.1);
        }
    }
}
.newsContent{
    display: inline-block;
    width: 60%;
    text-align: left;
    margin-left: 8px;
    position: relative;
    &:hover .title{
        color:#147BFF
    }
    .title {
        font-size: 12px;
        font-weight: 600;
        text-align: left;
        color: #2c3e5c;
        margin-bottom: 20px;
    }
    .summary {
        font-size: 16px;
        font-weight: 400;
        text-align: justify;
        color: #667fa3;
        margin-bottom: 26px;
    }
    .createdTime {
        margin-bottom: 0;
        opacity: 0.6;
        font-size: 12px;
        font-weight: 400;
        color: #667fa3;
        position: absolute;
        bottom: 0;
    }
}

// 新闻详情
.newsDetail {
    width: 100%;
    margin: auto;
    overflow: hidden;
    background: #EEEEEE;
    .content {
        margin: auto;
        background: #FFFFFF;
        padding: 0 30px;
        overflow: hidden;
        :global {
            .ant-list-lg .ant-list-item {
                padding: 32px 0;
            }
            .ant-list-pagination {
                margin-top: 32px;
            }
        }
    }
    .title {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        color: #667fa3;
        margin-bottom: 8px;
        padding-top: 24px;;
    }
    .time {
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        color: #999999;
    }
    .desc {
        font-size: 12px;
        font-weight: 400;
        text-align: justify;
        color: #666666;
        margin-bottom: 24px;
        img{
            max-width: 100% !important;
        }
        video{
            width: 100%;
        }
    }
}
.footer {
    // display: flex;
    // justify-content: space-between;
    // align-items: end;
    padding: 0 0 24px;
    text-align: left;
    font-size: 12px;
    p{
        text-align: left;
        font-size: 12px;
    }
    a:hover{
        color: #6EB4FF;
        font-size: 12px;
    }
}
.cardBox {
    margin: 30px;
}
.card {
    padding: 24px 0;
    border-bottom: 1px solid #eeeeee;
}
.btn {
    display: block;
    height: 30px;
    font-size: 12px; 
}
:global {
    .adm-infinite-scroll {
        font-size: 12px;
        color: #667FA3;
    }
}