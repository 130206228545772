.header {
  position: fixed;
  width: 100%;
  z-index: 99;
  height: 42px;
  .content {
    padding: 10px;
    height: 42px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo {
    width: 71px;
    height: 32px; 
  }
  .navigation {
    width: 50%;
    text-align: right;
    img {
      width: 30px;
      height: 30px;
    }
  }
}

.menu {
  background: #333333;
  padding: 16px;
  text-align: left;
  li {
    list-style:none;
    padding: 12px 0;
    &:not(:last-child) {
      border-bottom: 0.6px solid #4d4a4a;
    }
    > a {
      color:#ffffff;
      display: block;
      font-size: 12px;
    }
    
    &:hover > a{
      color: #87C0FA;;
    }
    .active {
      color: #2684FF;
      opacity: 1;
      font-weight: 600;
      text-decoration:none;
      margin-bottom: 0;
    }
  }
}