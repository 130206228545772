.header {
  position: fixed;
  width: 100%;
  z-index: 99;
  height: 72px;
  .content {
    width: 1200px;
    height: 72px;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
  img {
    width: 159px;
    height: 72px; 
  }
  li {
    padding:20px;
    display: inline;
    .default {
      color: #fff;
      opacity: 0.65;
      text-decoration:none;
      font-size: 18px;
      margin-bottom: 0;
      &::after{
        content: "";
        display: block; 
        transition: all 200ms ease-in-out 0s;
        cursor: pointer;
        border-bottom: 2px solid #fff;
        margin: 20px auto 0;
        width: 0;
      }
      &:hover{
        opacity: 1;
        font-weight: 600;
      }
      &:hover::after{
        width: 100%;
      }
    }
    .active {
      color: #fff;
      opacity: 1;
      font-weight: 600;
      text-decoration:none;
      font-size: 18px;
      margin-bottom: 0;
      &::after{
        content: "";
        display: block; 
        transition: all 200ms ease-in-out 0s;
        cursor: pointer;
        border-bottom: 2px solid #fff;
        opacity: 1;
        font-weight: 600;
        margin: 20px auto 0;
        width: 100%;
      }
    }
  }
}
