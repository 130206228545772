@import '@/App.less';

.medium {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}
.banner {
    height:440px;
    position: relative;
    img {
        width: 100%;
        height: 100%
    }
    .banner_title {
        position: absolute;
        left:50%;
        top:50%;
        width: 960px;
        h4 {
            margin: 0;
            font-size: 60px;
            font-weight: 500;
            text-align: center;
            color: #ffffff;
            line-height: 84px;
            text-shadow: 0px 2px 7px rgb(0 0 0 / 50%);
        }
    }
}


.panel1 {
    width: 1200px;
    margin: auto;
    height: 246px;
    padding: 60px 0;
    p {
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        color: #667fa3;
        margin-bottom: 0;
        height: 120px;
        padding: 10px 0;
    }
}

.panel2 {
    background: #f5f7fa;
    .content {
        width: 1200px;
        margin: auto;
        padding: 40px 0 70px;
        .title {
            font-size: 36px;
            font-weight: 600;
            text-align: center;
            color: #2c3e5c;
            line-height: 50px;
        }
        .title_img {
            height: 37px;
            margin-bottom: 40px;
        }
        p {
            margin-bottom: 13px;
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            color: #667fa3;
        }
        .logo_img {
            width: 885px;
            height: 96px;
            margin-bottom: 52px;
        }
        .advantageList {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .card {
                width: 580px;
                height: 382px;
                border-radius: 2px;
                box-shadow: 0px 2px 14px 0px rgba(118,118,118,0.19); 
                background: #ffffff;
                &:first-child{
                    margin-right: 12px;
                }
                &:last-child{
                    margin-left: 12px;
                }
                img {
                    width: 192px;
                    height: 147px;
                    margin-top:  42px;
                    margin-bottom: 12px;
                }
                h4{
                    font-size: 24px;
                    font-weight: 500;
                    text-align: center;
                    color: #2c3e5c;
                    line-height: 34px;
                }
                p{
                    width: 492px;
                    margin: auto;
                    font-size: 16px;
                    font-weight: 400;
                    text-align: left;
                    color: #667fa3;
                    line-height: 24px;
                }
            }
        }
    }
}

.panel3 {
    width: 1200px;
    margin: auto;
    padding: 70px 0;
    .title {
        font-size: 36px;
        font-weight: 600;
        text-align: center;
        color: #2c3e5c;
        line-height: 50px;
    }
    .title_img {
        height: 37px;
        margin-bottom: 17px;
    }
    .desc_img {
        width: 970px;
        height: 642px;
        margin: auto;
        img {
            width: 100%;
            height: 100%;
        }
    }
}

.panel4 {
    background: #f5f7fa;
    .content {
        width: 1200px;
        height: 642px;
        margin: auto;
        padding: 70px 0;
        .title{
            font-size: 36px;
            font-weight: 600;
            text-align: center;
            color: #2c3e5c;
            line-height: 50px;
        }
        .title_img {
            height: 37px;
            margin-bottom: 28px;
        }
        .diverseContent {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .card {
                width: 370px;
                height: 384px;
                border-radius: 2px;
                box-shadow: 0px 2px 14px 0px rgba(118,118,118,0.19); 
                background: #ffffff;
                .icon {
                    width: 94px;
                    height: 87px;
                    margin-top: 54px;
                }
                h4 {
                    font-size: 24px;
                    font-weight: 500;
                    text-align: center;
                    color: #2c3e5c;
                    line-height: 33px;
                    margin-top: 28px;
                    margin-bottom: 10px;
                }
                p {
                    width: 278px;
                    margin: auto;
                    text-align: left;
                }
                .desc {
                    margin-bottom: 24px;
                    color: #667FA3;
                    font-size: 16px;
                }
                .desc1 {
                    color: #2C3E5C;
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
    }
}

.panel5{
    margin: 78px auto 90px;
    .content {
        margin: auto;
        width: 1200px;
        height: 580px;
        .title {
            font-size: 36px;
            font-weight: 600;
            text-align: center;
            color: #2c3e5c;
            line-height: 50px;
            margin-bottom: 4px;
        }
        .title_img {
            height: 37px;
            margin-bottom: 50px;
        }
        .videoSwiper {
            overflow: hidden;
            position: relative;
            .swiper {
                width: 100%;
                padding-top: 50px;
                padding-bottom: 50px;
              }
        
            .swiper_slide {
                position: relative;
                background-position: center;
                background-size: cover;
                width: 780px;
                height: 439px;
                z-index: 50;
              }
              .videoPlay {
                  position: absolute;
                  z-index: 50;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  width: 137px;
                  height: 135px;
                  img {
                      width: 100%;;
                      height:100%
                  }
              }
              .videoContent{
                  z-index: 0;
                  background: rgba(0,0,0,0.27);
                  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
                  position: absolute;
                  width: 100%;
                  bottom: 0;
                  left:0;
                  padding: 10px 28px;
                  h4 {
                    font-size: 20px;
                    font-weight: 500;
                    text-align: left;
                    color: #ffffff;
                    line-height: 28px;
                    letter-spacing: 1px;
                    text-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50); 
                    margin-bottom: 0;
                  }
                  p{
                    font-size: 14px;
                    font-weight: 400;
                    text-align: left;
                    color: #ffffff;
                    line-height: 20px;
                    letter-spacing: 1px;
                    text-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50); 
                    margin-bottom: 0;
                  }
              }
        }
    }
}

.center1NewBox{
    height: 1660px;
    background-image: url('../../assets/images/medium/newBgc.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .topBox1{
        margin-top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        .textBox{
            font-size: 37px;
            font-weight: 500;
            color: #2C3E5C;
            text-align: center;
        }
        img{
            width: 62px;
            height: 62px;
            margin: 0 60px;
        }
    }
    .topBox2{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 66px;
        
        .thing{
            text-align: center;
            margin-right: 110px;
            img{
                width: 120px;
                height: 88px;
            }
            .line{
                font-size: 24px;
                color: #2C3E5C;
                font-weight: 500;
            }
        }
    }
    .thing:last-child{
        margin-right: 0px;
    }
    .topBox3{
        margin-top: 100px;
        text-align: center;
        font-size: 32px;
        color: #2C3E5C;
        font-weight: 600;
        img{
            width: 230px;
            height: 37px;
        }
    }
    .topBox4{
        width: 1100px;

        text-align: start;
        margin: 40px auto 0;
        font-size: 16px;
        color: #667FA3;
        .title{
            font-weight: 500;
        }
    }
    .topBox5{
        display: flex;
        justify-content: center;
        margin-top: 40px;
        .leftBox{
            text-align: center;
            font-size: 24px;
            color: #2C3E5C;
            margin-right:36px ;
            img{
                width: 352px;
                height: 663px;
                margin-bottom: 24px;
            }
        }
        .rightBox{
            .line1Box{
                display: flex;
                .imgBox{
                    font-size: 24px;
                    color: #2C3E5C;
                    margin-right: 35px;
                    img{
                        width: 339px;
                        height: 280px;
                        margin-bottom: 24px;
                    }
                }
                .imgBox:nth-child(2n){
                    margin-right: 0px;
                }
            }
        }

        
    }
}
.aliPayBox{
    position: relative;
    top: -33px;
    background-color: #fff;
    .content1{
        text-align: center;
        font-size: 32px;
        color: #2C3E5C;
        font-weight: 600;
        img{
            width: 271px;
            height: 37px;
        }
    }
    .content2{
        width: 1100px;
        font-size: 16px;
        color: #667FA3;
        margin: 40px auto 0;
        text-align: start;
    }
    .content3{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 64px;
        .thing1{
            margin-right: 300px;
            font-size: 24px;
            color: #2C3E5C;
            font-weight: 500;
            text-align: center;
            img{
                width: 74px;
                height: 74px;
                margin-bottom: 14px;
            }
        }
        .thing1:last-child{
            margin-right: 0px;
        }
    }
    .content4{
        margin-top: 40px;
        img{
            width: 240px;
            height: 520px;
            margin-right: 63px;
        }
        img:last-child{
            margin-right: 0px;
        }
    }
    .content5{
        margin-top: 100px;
        font-size: 32px;
        color: #2C3E5C;
        font-weight: 600;
        img{
            width: 262px;
            height: 37px;
        }
    }
    .content6{
        font-size: 16px;
        color: #667FA3;
        margin: 60px auto 0;
        width: 1100px;
        text-align: start;
    }
    .content7{
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #667FA3;
        width: 1100px;
        margin: 0 auto;
        text-align: start;
        .left{
            width: 137px;
            height: 57px;
            background: #fa8c16;
            line-height: 57px;
            font-size: 22px;
            font-weight: 700;
            color: #fff;
            text-align: center;
            margin-right: 40px;
        }
        img{
            width: 24px;
            height: 24px;
            margin-right: 8px;
        }
    }
    .content8{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
        .thing3{
            width: 181px;
            height: 181px;
            border-radius: 50%;
            color: #fff;
            text-align: center;
            margin-right: 124px;
            .title{
                font-size: 18px;
                font-weight: 700;
                margin-top: 45px;
            }
            .number{
                font-size: 22px;
                font-weight: 700;
                margin-top: 4px;
            }
            .label{
                font-size: 14px;
                margin-top: 15px;
            }
        }
        .thing3:last-child{
            margin-right: 0px;
        }
    }
}